<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <!-- <div class="card-header py-3"> -->
    <!-- <div class="card-title align-items-start flex-column"> -->
    <!-- <h3 class="card-label font-weight-bolder text- h1">
      Account Information
    </h3> -->
    <!-- <span class="text-muted font-weight-bold font-size-large mt-1"
      >You can Update your Account informaiton below</span> -->
    <!-- </div> -->
    <!-- </div> -->
    <!--end::Header-->
    <!--begin::Form-->

    <form class="form ml-20" @reset.prevent>
      <!--begin::Body-->
      <div class="card-body">
        <!-- <div class="row">
      
      <div class="col-lg-9 col-xl-6">
      <h5 class="font-weight-bold display-4 mb-6">Details</h5>
      </div>
      <label class="col-xl-6"></label>
    </div> -->
        <!-- <div class="form-group row"> -->
        <!-- <label class="col-xl-3 col-lg-3 col-form-label text-right"
      >Avatar</label
      > -->
        <!-- <div class="col-lg-9 col-xl-6">
      <div class="image-input image-input-outline" id="kt_profile_avatar">
        <div
        class="image-input-wrapper"
        :style="{ backgroundImage: `url(${photo})` }"
        ></div>
        <label
        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
        data-action="change"
        data-toggle="tooltip"
        title=""
        data-original-title="Change avatar"
        >
        <i class="fa fa-pen icon-sm text-muted"></i>
        <input
          type="file"
          name="profile_avatar"
          accept=".png, .jpg, .jpeg"
          @change="onFileChange($event)"
        />
        <input type="hidden" name="profile_avatar_remove" />
        </label>
        <span
        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
        data-action="cancel"
        data-toggle="tooltip"
        title="Cancel avatar"
        >
        <i class="ki ki-bold-close icon-xs text-muted"></i>
        </span>
        <span
        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
        data-action="remove"
        data-toggle="tooltip"
        title="Remove avatar"
        @click="current_photo = null"
        >
        <i class="ki ki-bold-close icon-xs text-muted"></i>
        </span>
      </div>
      <span class="form-text text-muted"
        >Allowed file types: png, jpg, jpeg.</span
      >
      </div> -->
        <!-- </div> -->
        <div class="details">Account Details</div>
        <div class="form-group">
          <label for="name" class="name">Forename</label>
          <div class="form-view">
            <input
              ref="name"
              id="name"
              tabindex="1"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="currentUser.userData.name"
              height="60"
            />
            <i class="far fa-edit"></i>
          </div>
        </div>
        <div class="form-group">
          <label for="name" class="name">Surname</label>
          <div class="form-view">
            <input
              ref="surname"
              id="surname"
              tabindex="1"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="currentUser.userData.surname"
              height="60"
            />
            <i class="far fa-edit"></i>
          </div>
        </div>

        <div class="form-group">
          <label for="company" class="company">Company Name</label>
          <div class="form-view">
            <input
              ref="company"
              id="company"
              tabindex="2"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="currentUser.userData.company"
            />
            <i class="far fa-edit"></i>
          </div>
        </div>

        <div class="form-group">
          <label class=""></label>
          <div class="form-view">
            <button
              type="reset"
              tabindex="3"
              class="btn btn-primary mr-2 py-2 px-10 h3 font-weight-bold"
              @click="save()"
              ref="kt_save_changes"
            >
              Save Changes
            </button>
          </div>
        </div>
        <div class="details">Account Email</div>
        <p class="emailContent">
          To change your email please enter a new one below. We will then send
          you an activation email to the new address with a link to confirm
        </p>
        <div class="form-group">
          <label for="email" class="name">Email</label>
          <div class="form-view">
            <input
              ref="email"
              type="email"
              tabindex="4"
              class="form-control form-control-lg form-control-solid"
              v-model="email"
            />
            <i class="far fa-edit"></i>
          </div>
        </div>

        <div class="form-group">
          <label class=""></label>
          <div class="form-view">
            <button
              type="reset"
              tabindex="5"
              class="btn btn-primary mr-2 py-2 px-10 h3 font-weight-bold"
              @click="change_email()"
              ref="kt_changeemail_changes"
            >
              Change Email
            </button>
          </div>
        </div>

        <!-- <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
      <h3 class="font-weight-bold mt-10 mb-6">Account Email</h3>
      </div>
    </div>

    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-3">
      <h4 class="font-weight-bold mt-10 mb-6">To change your email please enter a new one below. We will then send you an activation email to the new address with a link to confirm</h4>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-xl-3 col-lg-3 col-form-label text-right"
      >Email Address</label
      >
      <div class="col-lg-9 col-xl-5">
      <div class="input-group input-group-lg input-group-solid"> -->
        <!-- <div class="input-group-prepend"> -->
        <!-- <span class="input-group-text">
          <i class="la la-at"></i>
        </span> -->
        <!-- </div> -->
        <!-- <input
        ref="email"
        type="email"
        class="form-control form-control-lg form-control-solid"
        v-model="email"                
        style="border: 1px solid #d0c6c6;"
        />
      </div>
      </div>          

      <div class="col-lg-9 col-xl-4 d-flex align-items-center">
        <span class="floating-placeholder span12 text-center text-danger h4" v-if="msg.email">{{msg.email}}</span>
      </div>              

    </div>
    

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label text-right"
      ></label>
      <div class="col-lg-9 col-xl-3" >
      <button
      type="reset"
      class="btn btn-success mr-2 p-5"
      @click="change_email()"
      ref="kt_changeemail_changes"
    >
    Change Email
    </button>
      </div>
    </div>
       -->
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  UPDATE_USER_INFO,
  UPDATE_AVATAR,
  CHANGE_EMAIL,
} from "@/core/services/store/auth.module";

import Swal from "sweetalert2";

export default {
  name: "PersonalInformation",
  data() {
    return {
      default_photo: "media/users/blank.png",
      current_photo: null,
      email: "",
      msg: [],
    };
  },
  mounted() {
    this.current_photo =
      process.env.VUE_APP_BACKEND_ENDPOINT + this.currentUser.userData.avatar;
    this.email = this.currentUser.userData.email;
  },
  watch: {
    email(value) {
      // binding this to the data value in the email input
      this.validateEmail(value);
    },
  },
  methods: {
    validateEmail(value) {
      var newMail = value;
      const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
      if (newMail === "") {
        this.msg["email"] = "!! Please enter a valid email address";
        return { valid: false };
      }
      if (!reg.test(newMail)) {
        this.msg["email"] = "!! Please enter a valid email address";
        return { valid: false };
      }

      this.msg["email"] = "";
      return { valid: true };
    },
    save() {
      var name = this.$refs.name.value;
      var surname = this.$refs.surname.value;
      var company = this.$refs.company.value;
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send update request
      this.$store.dispatch(
        UPDATE_USER_INFO,
        {
          name,
          company,
          surname,
        },
        this.currentUser.userData.id
      );

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );

      Swal.fire({
        title: "Your data has been updated",
        confirmButtonClass: "btn btn-secondary",
      });
    },
    change_email() {
      var new_email = this.email;
      var current_email = this.currentUser.userData.email;

      // set spinner to submit button
      const submitButton1 = this.$refs["kt_changeemail_changes"];
      submitButton1.classList.add("spinner", "spinner-light", "spinner-right");

      // send update request
      this.$store.dispatch(CHANGE_EMAIL, { new_email, current_email });

      submitButton1.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );

      Swal.fire({
        title:
          "Confirmation email has been sent to your new email address. Please verify within the next 5 minutes to change your account email.",
        confirmButtonClass: "btn btn-secondary",
      });
    },
    // cancel() {

    //   this.$refs.name.value = this.currentUser.userData.name;
    //   this.$refs.company.value = this.currentUser.userData.company;
    //   this.$refs.email.value = this.currentUser.userData.email;

    // },
    onFileChange(e) {
      const file = e.target.files[0];

      let data = new FormData();

      data.append("avatar", file);

      const user = this.currentUser.userData;

      this.$store.dispatch(UPDATE_AVATAR, {
        file,
        user,
      });

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
};
</script>
<style scoped>
.form-group label {
  height: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */
  text-align: right;
  letter-spacing: -0.408px;

  color: #0e0e0f;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.form-control.form-control-solid {
  font-size: 17px;
  font-weight: 400;
  width: 688px;
  height: 50px;
  letter-spacing: -0.408px;
  font-feature-settings: "case" on;
  background: #f4f5f9;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  border-radius: 8px;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  border: 0;
  color: #6f7392;
}

.form-view i {
  position: absolute;
  color: #aaafb6;
  width: 24px;
  height: 24px;
  margin-left: 650px;
  margin-top: -36px;
}

.details {
  width: 165px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 20px;
  /* identical to box height */

  color: #0e0e0f;
}

.fa-edit {
  width: 24px;
  height: 24px;
}

.form-view button {
  width: 215px;
  margin-top: -36px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  /* identical to box height, or 129% */
  text-align: center;
  font-feature-settings: "case" on;

  color: #ffffff;
}

.emailContent {
  width: 688px;
  color: #6f7392;
  line-height: 2;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 15px;
}
</style>
