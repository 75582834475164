<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="py-3">
      <!-- <div class="card-title align-items-start flex-column ml-20 mt-8">
        <h3 class="card-label font-weight-bolder text-dark" style="font-size:25px">Change Password</h3>
        <span class="text-muted font-weight-bold font-size-large mt-1"
          >Change your account password</span
        >
      </div> -->
      <!-- <div class="card-toolbar" style="margin-top: 68px;margin-left: -886px;">
        <button
          type="submit"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div> -->
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form ml-20" id="kt_password_change_form">
      <div class="card-body mt-5">
        <div class="details">Change Password</div>
        <!-- <p class="" style="color: #6F7392;line-height: 2;margin-bottom: 15px;font-size: 15px;">Change your account password</p> -->

        <!--begin::Alert-->
        <!--end::Alert-->
        <div class="form-group mt-7">
          <label class="">Current Password </label>

          <div class="form-view">
            <input
              :type="switchVisibility(showCurrentPassword)"
              tabindex="1"
              class="form-control form-control-lg form-control-solid mb-2"
              value=""
              placeholder="Current password"
              name="current_password"
              ref="current_password"
            />
            <button
              class="eyeview"
              v-if="showCurrentPassword"
              @click="showCurrentPassword = !showCurrentPassword"
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>
            <button
              class="eyeview"
              v-if="!showCurrentPassword"
              @click="showCurrentPassword = !showCurrentPassword"
            >
              <i class="fa fa-eye-slash" aria-hidden="true"></i>
            </button>
            <!-- <i class="fa fa-eye" aria-hidden="true"></i> -->
            <!-- <v-icon small v-if="showCurrentPassword" @click="showCurrentPassword = !showCurrentPassword">mdi-eye</v-icon> -->
            <!-- <v-icon small v-if="!showCurrentPassword" @click="showCurrentPassword = !showCurrentPassword">mdi-eye-off</v-icon> -->
          </div>
          <!-- <div class=""> -->
        </div>

        <div class="form-group">
          <label class="">New Password </label>

          <div class="form-view">
            <input
              :type="switchVisibility(showNewPassword)"
              class="form-control form-control-lg form-control-solid"
              tabindex="2"
              value=""
              placeholder="New password"
              name="new_password"
              ref="new_password"
            />
            <button
              class="eyeview"
              v-if="showNewPassword"
              @click="showNewPassword = !showNewPassword"
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>
            <button
              class="eyeview"
              v-if="!showNewPassword"
              @click="showNewPassword = !showNewPassword"
            >
              <i class="fa fa-eye-slash" aria-hidden="true"></i>
            </button>
            <!-- <v-icon small v-if="showNewPassword" @click="showNewPassword = !showNewPassword">mdi-eye</v-icon>
          <v-icon small v-if="!showNewPassword" @click="showNewPassword = !showNewPassword">mdi-eye-off</v-icon> -->
          </div>
        </div>

        <div class="form-group">
          <label class="verify_password">Verify Password </label>

          <div class="form-view">
            <input
              :type="switchVisibility(showVerifyPassword)"
              class="form-control form-control-lg form-control-solid"
              tabindex="3"
              value=""
              placeholder="Verify password"
              name="verify_password"
              ref="verify_password"
            />
            <button
              class="eyeview"
              v-if="showVerifyPassword"
              @click="showVerifyPassword = !showVerifyPassword"
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>
            <button
              class="eyeview"
              small
              v-if="!showVerifyPassword"
              @click="showVerifyPassword = !showVerifyPassword"
            >
              <i class="fa fa-eye-slash" aria-hidden="true"></i>
            </button>
            <!-- <v-icon small v-if="showVerifyPassword" @click="showVerifyPassword = !showVerifyPassword">mdi-eye</v-icon>
          <v-icon small v-if="!showVerifyPassword" @click="showVerifyPassword = !showVerifyPassword">mdi-eye-off</v-icon> -->
          </div>
        </div>
        <!-- <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >New Password
          </label>
          <div class="col-lg-9 col-xl-3">
            <input
            :type="switchVisibility(showNewPassword)"
              class="form-control form-control-lg form-control-solid"
              value=""
              placeholder="New password"
              name="new_password"
              ref="new_password"
            />
          </div>
            <div class="col-lg-9 col-xl-3">
              <v-icon small v-if="showNewPassword" @click="showNewPassword = !showNewPassword">mdi-eye</v-icon>
          <v-icon small v-if="!showNewPassword" @click="showNewPassword = !showNewPassword">mdi-eye-off</v-icon>
            </div>
          
         
        </div> -->

        <!-- <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Verify Password
           
            </label>
          <div class="col-lg-9 col-xl-3">
           
            <input
            :type="switchVisibility(showVerifyPassword)"
              class="form-control form-control-lg form-control-solid"
              value=""
              placeholder="Verify password"
              name="verify_password"
              ref="verify_password"
            />
          </div>
            <div class="col-lg-9 col-xl-3">
              <v-icon small v-if="showVerifyPassword" @click="showVerifyPassword = !showVerifyPassword">mdi-eye</v-icon>
          <v-icon small v-if="!showVerifyPassword" @click="showVerifyPassword = !showVerifyPassword">mdi-eye-off</v-icon>
            </div>
           
          
        </div> -->
        <div class="form-group">
          <!-- <label class="col-xl-3 col-lg-3 col-form-label text-alert ml-4"
            ></label
          > -->
          <div class="form-view">
            <button
              tabindex="4"
              type="submit"
              class="btn btn-primary h3 py-2 px-10 font-weight-bold mr-2"
              @click="save()"
              ref="kt_save_changes"
            >
              Save Changes
            </button>
          </div>

          <!-- <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button> -->
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PASSWORD } from "@/core/services/store/auth.module";
import KTUtil from "@/assets/js/components/util";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";

export default {
  name: "ChangePassword",
  data() {
    return {
      password: "",
      status: "",
      valid: true,
      showCurrentPassword: false,
      showNewPassword: false,
      showVerifyPassword: false,
    };
  },
  mounted() {
    const password_change_form = KTUtil.getById("kt_password_change_form");
    const strongPassword = function () {
      return {
        validate: function (input) {
          const value = input.value;
          if (value === "") {
            return {
              valid: true,
            };
          }

          // Check the password strength
          if (value.length < 10) {
            return {
              valid: false,
            };
          }

          // The password does not contain any uppercase character
          if (value === value.toLowerCase()) {
            return {
              valid: false,
            };
          }

          // The password does not contain any uppercase character
          if (value === value.toUpperCase()) {
            return {
              valid: false,
            };
          }

          // The password does not contain any digit
          if (value.search(/[0-9]/) < 0) {
            return {
              valid: false,
            };
          }

          return {
            valid: true,
          };
        },
      };
    };
    this.fv = formValidation(password_change_form, {
      fields: {
        current_password: {
          validators: {
            notEmpty: {
              message: "Current password is required",
            },
            checkPassword: {
              message:
                "The  Current password is too weak it need  to be minimum of 10 characters and contain on special character and one number and one upper case character",
            },
          },
        },
        new_password: {
          validators: {
            notEmpty: {
              message: "New password is required",
            },
            checkPassword: {
              message:
                "The New password is too weak it need  to be minimum of 10 characters and contain on special character and one number and one upper case character",
            },
          },
        },
        verify_password: {
          validators: {
            notEmpty: {
              message: "Confirm password is required",
            },
            identical: {
              compare: function () {
                return password_change_form.querySelector(
                  '[name="new_password"]'
                ).value;
              },
              message: "The password and its confirm are not the same",
            },
            checkPassword: {
              message:
                "The Confirm password is too weak it need  to be minimum of 10 characters and contain on special character and one number and one upper case character",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
        submitButton: new SubmitButton(),
      },
    }).registerValidator("checkPassword", strongPassword);
  },
  methods: {
    switchVisibility(flag) {
      return flag ? "text" : "password";
    },
    save() {
      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        var password = this.$refs.new_password.value;
        const submitButton = this.$refs["kt_save_changes"];

        // set spinner to submit button
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send update request
          this.$store
            .dispatch(UPDATE_PASSWORD, { password })
            // go to which page after successfully update
            .then(() => this.$router.push({ name: "dashboard" }));

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      });

      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      });
    },
    cancel() {
      this.fv.resetForm();
      this.$refs.current_password.value = "";
      this.$refs.new_password.value = "";
      this.$refs.verify_password.value = "";
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
<style scoped>
/* .form-group{
  display: flex;
    align-items: center;
} */
.form-group label {
  height: 20px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */
  text-align: right;
  letter-spacing: -0.408px;

  color: #0e0e0f;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.form-view {
  position: relative;
  width: 40%; /* Adjust the container width as needed */
}

.form-control.form-control-solid {
  font-size: 17px;
  font-weight: 400;
  width:'auto'; 
  height: 50px;
  padding-right: 40px; 
  letter-spacing: -0.408px;
  font-feature-settings: "case" on;
  background: #f4f5f9;
  border-radius: 8px;
  text-align: left;
  box-sizing: border-box;
  border: 0;
  color: #6f7392;
  position: relative; /* Ensure the input stays in place for the eye button */
}

.form-view .eyeview {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%); 
  color: #6f7392;
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.details {
  width: 200px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;

  /* identical to box height */

  color: #0e0e0f;
}

.form-view button {
  width: 215px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  /* identical to box height, or 129% */
  text-align: center;
  font-feature-settings: "case" on;

  color: #ffffff;
}
</style>
